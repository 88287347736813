<template>
  <div class="my">
    <!-- <div class="top">
      <img class="left" src="../assets/home/logo.jpg" alt="" />
      <div class="right">
        <img src="../assets/my/set.png" @click="go('/setting')" alt="" />
      </div>
    </div> -->
    <div class="MeHeadImage">
      <p class="title">Account Balance（USDT))</p>
      <p class="number">≈{{ info }}</p>
    </div>
    <div class="feature">
      <div @click="toCZ">
        <img src="../assets/my/i1.png" alt="Recharge" />
        <p>Recharge</p>
      </div>
      <div style="" @click="toTX">
        <img src="../assets/my/i2.png" alt="Withdraw" />
        <p>Withdraw</p>
      </div>
      <!-- <div>
        <img src="../assets/my/i3.png" alt="充值" />
        <p>记录</p>
      </div> -->
    </div>
    <!-- <div class="data-title">
      <div class="left">
        <img src="../assets/my/data.png" alt="" />
        <span>数据统计</span>
      </div>
      <div class="right">
        <span>每日20:00（utc+8）更新</span>
        <img src="../assets/my/right.png" alt="" />
      </div>
    </div>
    <div class="data">
      <img src="../assets/my/none.png" alt="" />
      <p>暂无数据</p>
    </div> -->
    <div class="list-title">
      <div class="left">
        <span style="font-weight: bolder;">Asset List</span>
      </div>
      <div class="right">
        <!-- <span>查看更多资产记录</span>
        <img src="../assets/my/right.png" alt="" /> -->
      </div>
    </div>
    <div class="list-item" v-for="(item, index) in infoList" :key="index">
      <div class="left">
        <img v-if="item.symbol == 'BTC'" src="../assets/home/btc.png" alt="" />
        <img
          v-else-if="item.symbol == 'USDT'"
          src="../assets/home/usdt.png"
          alt=""
        />
        <img v-else src="../assets/home/ETH-5@2x.png" alt="" />
        <span>{{ item.symbol }}</span>
      </div>
      <div class="right">
        {{ item.amount }}
      </div>
    </div>
    <!-- <div class="list-title">
      <div class="left">
        <span style="font-weight: bolder;">Transaction Records</span>
      </div>
      <div class="right">
        <span>查看更多</span>
        <img src="../assets/my/right.png" alt="" />
      </div>
    </div>
    <div class="list-item" v-for="(item, index) in infoList" :key="index">
      <div class="left">
        <img src="../assets/home/btc.png" alt="" />
        <span>{{ item.symbol }}</span>
      </div>
      <div class="right">
        {{ item.amount }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "my",
  data() {
    return {
      isShowExitView: false,
      info: "",
      infoList: [],
    }
  },
  created() {
    this.getMeInforMation()
  },
  components: {},
  mounted() {},
  methods: {
    go(url) {
      this.$router.push(url)
    },
    // 获取我的信息
    getMeInforMation() {
      this.$request
        .get("/user/assets")
        .then(
          (res) => {
            if (res.code == 200) {
              this.info = res.data.assets[0].amount
              this.infoList = res.data.assets
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((err) => {
          this.$toast(err)
        })
    },
    //点击退出
    exit() {
      this.isShowExitView = true
    },
    //充值
    toCZ(symbol) {
      this.$router.push({
        path: "/topup",
        query: {
          symbol: symbol,
        },
      })
    },
    //提现
    toTX(symbol) {
      this.$router.push({
        path: "/Withdrawal",
        query: {
          symbol: symbol,
        },
      })
    },
    //去交易记录
    gotoRecordList(symbol) {
      if (symbol != "") {
        this.$router.push({
          path: "/MentionRecord",
          query: {
            symbol: symbol,
          },
        })
      } else {
        this.$router.push({
          path: "/electricity",
          // query: {
          //   symbol: symbol
          // }
        })
      }
    },
    //确定退出
    sure() {
      localStorage.removeItem("token")
      this.$router.push("/login")
      this.isShowExitView = false
    },
    //取消退出
    cancel() {
      this.isShowExitView = false
    },
  },
}
</script>
<style scoped lang="scss">
.my {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  background: #f9f9f9;
  padding: 0 20px 60px;
  .top {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 100px;
      height: 30px;
    }
    .right {
      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  .MeHeadImage {
    width: 100%;
    height: 180px;
    background: url("../assets/my/money.png");
    background-repeat: no-repeat;
    background-position: -14px -1px; /* 图像距离顶部10px，左边20px */
    background-size: cover; /* 保持图像原始宽高比，尽可能包含在元素内 */
    text-align: left;
    padding: 0 0 0 20px;
    .title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #999999;
      padding-top: 40px;
    }
    .number {
      font-family: DINPro-Bold, DINPro-Bold;
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      margin-top: 13px;
    }
  }

  .feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
      width: 160px;
      height: 60px;
      background: #e1e1e1;
      border-radius: 8px;
      display: flex;
      // flex-direction: column;
      // padding: 0px 0 8px;
      align-items: center;
      justify-content: center;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      // justify-content: space-between;
      background: #f8de4d;
      color: #333333;
      img {
        width: 30px;
        margin-right: 10px;
      }
    }
  }

  .data-title {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 16px 12px 0 16px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    margin-top: 20px;
    .left {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      color: #4a4a4a;
      img {
        width: 28px;
        margin-right: 8px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      color: #999999;
      img {
        width: 6px;
        margin-left: 14px;
      }
    }
  }
  .data {
    width: 100%;
    height: 110px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    img {
      width: 34px;
      margin-top: 32px;
      margin-bottom: 8px;
    }
  }
  .list-title {
    display: flex;
    justify-content: space-between;
    padding: 16px 12px 0 16px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    margin-top: 30px;
    margin-bottom: 12px;
    .left {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 18px;
      color: #333333;
    }
    .right {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      color: #999999;
      img {
        width: 6px;
        margin-left: 14px;
      }
    }
  }
  .list-item {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    padding: 0 20px;
    margin-bottom: 10px;
    .left {
      display: flex;
      align-items: center;
    }
    span {
      font-weight: 700;
      font-size: 16px;
      color: #333333;
    }
    .right {
      font-weight: 700;
      font-size: 16px;
      color: #333333;
    }
    img {
      width: 30px;
      margin-right: 10px;
    }
  }
}
</style>
